<template>
  <div :class="[className , 'common_menu']">
    <h3>目录</h3>
    <div id="list">
      <p 
        :class="{'active': tabIndex == index }" 
        v-for="(item, index) in headList" 
        :key="item.id"
        @click="tabClick(item, index)">
          ● <span>{{ item.text }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['height', 'headList'],
  data() {
    return {
      className: '',
      tabIndex: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollClick)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollClick)
  },
  methods: {
    scrollClick() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var menu = document.getElementsByClassName('common_menu')[0];
      var teacher = document.getElementsByClassName('course_teacher')[0];
      
      if(teacher) {
        if(scrollTop < (teacher.offsetTop + teacher.offsetHeight - 134) ) {
          this.className = ''
        }else {
          this.className = 'fixed'
        }
      }else {
        let bread = document.getElementsByClassName('common_bread')[0];
        let top = bread ? 170 : 134
        if(menu.offsetTop - scrollTop <= top) {
          this.className = 'fixed'
        }else {
          this.className = ''
        }
      }
      this.headList.forEach((element, index) => {
        let id = document.getElementById(element.id)
        if(id.offsetTop - scrollTop <= 134 && (id.offsetTop + id.offsetHeight) > 134) {
          this.tabIndex = index;
        }
      });
      // let active = document.getElementsByClassName('active')[0].offsetTop;
      // let list = document.getElementById('list');
      // if(active - list.offsetHeight >= 0) {
      //   list.style.animate = ({scrollTop: active}, 2000)
      // }
    },
    tabClick(item, index) {
      this.tabIndex = index;
      window.scrollTo({
        top: document.getElementById(item.id).offsetTop - 134,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang='scss'>
.common_menu {
  background: #fafafa;
  border-radius: 4px;
  padding: 10px 0 20px;
  margin-top: 20px;
  width: 280px;
  max-height: calc(100% - 400px);
  min-height: 76px;
  overflow: hidden;

  // .list {
  //   position: relative;
  // }

  & > h3 {
    font-size: 16px;
    color: #303133;
    padding: 9px 20px;
  }

  div {
    max-height: calc(100vh - 500px);
    min-height: 40px;
    // overflow-y: scroll;
    overflow-x: hidden;
    // width: 300px;

    p {
      padding: 10px 20px;
      font-size: 14px;
      color: #dadada;
      line-height: 20px;
      cursor: pointer;
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & > span { 
        margin-left: 5px;
        color: #303133;
      }

      &:hover {
        color: #05A081;

        & > span {
          font-weight: 600;
          color: #05A081;
        }
        
      }
    }

    .active {
      color: #05A081;

      & > span {
        font-weight: 600;
        color: #05A081;
      }
    }
  }
}
.common_menu.fixed {
  position: fixed;
  top: 134px;
}
</style>