<template>
  <div id="articleDetail" v-wechat-title='title'>
    <CommonBread></CommonBread>
    <div class="common_layout">
      <div class="layout_left">
        <h2>{{ detail.title }}</h2>
        <div class="tip clearfix">
          <div class="pull-left">
            <span class="first"><svg-icon icon-class="hashtag"></svg-icon>{{ detail.classificationName }}</span>
            <span>{{ detail.publishedDate && detail.publishedDate.split(' ')[0] }}</span>
            <a-divider direction="vertical" />
            来源：<a v-if="detail.sourceUrl" :href="detail.sourceUrl" target="_blank">{{ detail.sourceName }}</a>
            <span v-else>{{ detail.sourceName }}</span>
          </div>
          <div class="pull-right">
            <span>
              {{ detail.traffic }}<span style="margin: 0 5px;">阅读</span>
              <span>
                <span style="margin-right: 5px;">·</span>{{ detail.collection }}
                <span style="margin-left: 5px;">收藏</span>
              </span>
            </span>
            <a-button v-if="$route.name != 'checkLine'" type="outline" @click="collectClick">
              <template v-if="!detail.collectionFlag"><svg-icon icon-class="star_border"></svg-icon>收藏</template>
              <template v-if="detail.collectionFlag"><svg-icon icon-class="star" class-name="shou"></svg-icon>已收藏</template>
            </a-button>
          </div>
          

        </div>
        <a-divider />
        <a-image
          :src="indexImg"
          style="display: none;"
          ref="previewImg"
        />
        <div class="w-e-text" v-html="detail.content"></div>
        <CommonFilesTable v-if="detail.attachmentList && detail.attachmentList.length > 0" :data="detail.attachmentList" action="download"></CommonFilesTable>
        <a-button  class="show" @click="collectClick" type="primary" v-if="!detail.collectionFlag && $route.name != 'checkLine'"><svg-icon icon-class="star_border"></svg-icon>收藏文章</a-button>
        <a-button class="show" @click="collectClick" v-if="detail.collectionFlag && $route.name != 'checkLine'"><svg-icon icon-class="star" class-name="shou"></svg-icon>已收藏</a-button>
        <p class="bottom_tip" v-if="$route.name == 'policiesAndRegulationsDetail'">本网页所发布内容仅供参考，原文内容请以官方发布为准</p>
        <p class="bottom_tip" v-else>本网页所发布的内容来源于第三方，如有侵权，请点击页面下方「意见反馈」按钮进行侵权举报</p>
      </div>
      <div class="layout_right" v-if="detail.cateList && detail.cateList.length > 0">
        <CommonMenu :headList="detail.cateList"></CommonMenu>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread.vue'
import CommonMenu from '@/components/CommonMenu.vue'
import CommonFilesTable from '../../components/CommonFilesTable.vue'
export default {
  components: {
    CommonBread,
    CommonMenu,
    CommonFilesTable
  },
  data() {
    return {
      detail: {},
      title: '广东省碳达峰碳中和标准化技术信息公共服务平台',
      indexImg: null
    }
  },
  mounted() {
    this.showPage();
  },
  beforeDestory() {
    document.querySelectorAll('.preview-img').forEach(element => {
      element.removeEventListener('click', this.perviewClickEvent, true)
    });
  },
  methods: {
    showPage() {
      let api = this.$route.name != 'checkLine' ? 'ArticleDetail' : 'DownArticleDetail'
      this.$API[api]({
        id: parseInt(this.$route.params.id)
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.content =  res.data.data.content.replace(
            /(\<img[^\<\>])/g,
            (a, p1, p2, c, d) => {
              return `${p1} class="preview-img" `;
            }
          )
          this.detail = res.data.data
          this.title = `${res.data.data.title} - 广东省碳达峰碳中和标准化技术信息公共服务平台`
          this.$nextTick(() => {
            setTimeout(() => {
              document.querySelectorAll('.preview-img').forEach(element => {
                element.addEventListener('click', this.perviewClickEvent, true)
              });
            }, 1000)
          })
        }else {
          this.$router.replace('/403')
        }
      })
    },
    collectClick() {
      if(!this.$store.state.loginIn) return this.$store.commit('open_type', 1)
      let api = this.detail.collectionFlag ? 'CancelArticle' : 'CollectArticle'
      this.$API[api]({
        articleId: this.detail.id
      }, 3).then(res => {
        if(res.data.code == 200) {
          this.detail.collectionFlag = !this.detail.collectionFlag
          this.$message.success('操作成功')
        }else{
          this.$message.success(res.data.msg || '操作失败')
        }
      })
    },
    perviewClickEvent(evtData) {
      this.previewImg(evtData.target.getAttribute('src'))
    },
    previewImg(img) {
      this.indexImg = img;
      this.$refs.previewImg.onImgClick();
    }
  }
}
</script>

<style lang='scss'>
  #articleDetail {
    .layout_left {
      padding: 0 10px;
      & > h2 {
        font-size: 32px;
        color: #303133;
        margin: 20px 0;
        line-height: 45px;
      }

      .arco-divider-horizontal {
        margin: 20px 0 40px;
      }

      .tip {
        padding-right: 10px;
        
        .pull-left {
          line-height: 40px;
          display: flex;
          align-items: center;
          color: #606266;

          .arco-divider {
            border-left: 1px solid #c3c5c7;
            margin: 0 20px;
          }

          & > span,
          & > a {
            font-size: 14px;
            color: #606266;
            line-height: 20px;
            text-decoration: none;

            
          }
          & > a {
            &:hover {
              text-decoration: underline;
            }
          }
          
          .first {
            background: #eaf4ff;
            border-radius: 14px;
            padding: 0 10px;
            font-size: 12px;
            color: #0299ff;
            line-height: 28px;
            height: 28px;
            display: inline-block;
            margin-right: 15px;

            .svg-icon {
              width: 16px;
              height: 16px;
              margin-right: 3px;
              display: inline-block;
              vertical-align: middle;
              margin-top: -4px;
            }
          }
        }

        .arco-divider-horizontal {
          background: #e5e6eb;
          margin: 20px 0 60px;
        }

        .pull-right {
          display: flex;
          align-items: center;

          .arco-btn {
            width: 93px;
            border-color: #DCDFE6;
            color: #4E5969;
            display: flex;
            align-items: center;

            &:hover {
              color: #86909C;

              .svg-icon {
                color: #FEAE18;
              }
            }

            .svg-icon {
              width: 22px;
              height: 22px;
              margin-right: 5px;
              color: #4E5969;
            }

            .shou {
              color: #FEAE18;
            }
          }

          & > span {
            font-size: 12px;
            color: #86909c;
            line-height: 40px;
            margin-right: 25px;
          }
          
        }

        
      }

      .show {
        width: 152px;
        height: 60px;
        border-radius: 30px;
        margin: 40px auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }

      .bottom_tip {
        font-size: 14px;
        color: #86909c; 
        margin-top: 30px;
        text-align: center; 
        margin-bottom: 10px;
      }
    }
    .arco-btn-size-medium {
      border-radius: 4px;
    }

    .common_menu {
      margin-top: 0;
    }

    .common_menu.fixed {
      top: 170px;
    }
  }
</style>